import {BsFillPlayFill} from 'react-icons/bs';
import {FaInstagram, FaTelegramPlane, FaTwitter} from 'react-icons/fa';
import DispersiveList from '../../components/dispersiveList/DispersiveList';
import Button, {ButtonStyle} from '../../components/form/button/Button';
import MotionButton, {
  ButtonAnimations,
} from '../../components/form/button/MotionButton';
import BookAnimation from './Book';
import Pricing from '../../components/pricing/Pricing';
import './Main.scoped.css';
import ScrollIntoView from 'react-scroll-into-view';
import YouTube from 'react-youtube';
import {useCallback, useRef, useState} from 'react';
import TournamentsCard from '../../components/tournamentsCard/TournamentsCard';
import ContactSalesPopupWrapper from '../../components/contactSalesPopup/ContactSalesPopupWrapper';
import {useSearchParams} from 'react-router-dom';
import IntegrationSampleElement from '../../components/integrationsGallery/IntegrationSampleElement';

const Main: React.FC = () => {
  const ytVideo = useRef<YouTube>(null);

  const playVideo = useCallback(() => {
    ytVideo.current?.internalPlayer.playVideo();
  }, []);

  const [searchParams] = useSearchParams();
  const [requestDemoParam] = useState(
    !!searchParams.get('request-demo') || false,
  );

  return (
    <div className="flex-col space-y-16 my-8">
      <div className="grid lg:grid-cols-2">
        {/* Gamify | Play | Explore */}
        <div className="flex flex-col lg:justify-center items-center lg:items-start space-y-8 text-5xl ">
          {/* <div className="font-bold text-center lg:text-left flex flex-wrap gap-3 justify-center">
            <ScrollIntoView
              selector="#gamify"
              className="hover:text-white/80 cursor-pointer"
              scrollOptions={{
                block: 'center',
              }}
            >
              <span>Gamify</span>
            </ScrollIntoView>
            <span className="font-normal">|</span>
            <ScrollIntoView
              selector="#gamify"
              className="hover:text-white/80 cursor-pointer"
              scrollOptions={{
                block: 'center',
              }}
            >
              <span>Play</span>
            </ScrollIntoView>
            <span className="font-normal">|</span>
            <ScrollIntoView
              selector="#tournaments"
              className="hover:text-white/80 cursor-pointer"
              scrollOptions={{
                block: 'center',
              }}
            >
              <span>Explore</span>
            </ScrollIntoView>
          </div> */}
          <div className="font-bold text-5xl text-center lg:text-left">
            Turn your website into a game
          </div>
          <div className="flex flex-col lg:flex-row gap-x-2 gap-y-4">
            <div className="z-50">
              <ContactSalesPopupWrapper isOpened={requestDemoParam}>
                <Button className="py-2 h-full" id="request-demo">
                  Request a demo
                </Button>
              </ContactSalesPopupWrapper>
            </div>

            {/* <div className="flex gap-1 justify-center py-1">
              <a href="https://t.me/stemmy_io">
                <MotionButton
                  type="submit"
                  buttonStyle={ButtonStyle.Round}
                  animation={ButtonAnimations.Pulse}
                  animateDelay={0.15}
                >
                  <FaTelegramPlane />
                </MotionButton>
              </a>
              <a href="https://twitter.com/stemmy_io">
                <MotionButton
                  type="submit"
                  buttonStyle={ButtonStyle.Round}
                  animation={ButtonAnimations.Pulse}
                  animateDelay={0.3}
                >
                  <FaTwitter />
                </MotionButton>
              </a>
              <a href="https://www.instagram.com/stemmy.io">
                <MotionButton
                  type="submit"
                  buttonStyle={ButtonStyle.Round}
                  animation={ButtonAnimations.Pulse}
                  animateDelay={0.45}
                >
                  <FaInstagram />
                </MotionButton>
              </a>
            </div> */}
          </div>
        </div>
        <div className="relative">
          <div className="main-spot main-spot1 top-0 left-0 scale-150">
            <img
              src="./assets/spot-03.png"
              className="img-spot-main"
              alt="Spot 01"
            />
          </div>
          <BookAnimation className="w-full h-full" />
        </div>
      </div>
      <div className="flex-auto bg-gray-800 rounded-[30px] p-8 flex flex-col lg:flex-row gap-6">
        {/* SDK */}
        <div className="h-[100px] aspect-square lightningGlow flex-auto justify-center items-center hidden lg:flex">
          <img
            src="/assets/lightning.png"
            alt="lightning"
            // className="pointer-events-none select-none block object-center object-cover"
            className="h-[100px]"
          />
        </div>
        <div className="flex-auto text-xl lg:text-2xl shrink space-y-2">
          <p>
            Stemmy is a plug-in for Gamification. Turn your website into a game
            and attract more users via a gaming experience.
          </p>
        </div>
        <div className="flex-1">
          <ScrollIntoView
            selector="#gamify"
            className="block w-full lg:w-64 rounded-[20px] h-full w-full bg-gray-600 flex flex-col p-3 cursor-pointer"
            onClick={() => playVideo()}
            scrollOptions={{
              block: 'center',
            }}
          >
            <div className="flex-1">
              <BsFillPlayFill className="w-16 h-16 block mx-auto" />
            </div>
            <div className="text-center text-lg flex-1">View video</div>
          </ScrollIntoView>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-4" id="gamify">
        <div className="flex flex-col justify-center">
          <div className="text-4xl md:text-5xl lg:text-6xl font-bold">
            Ready to Gamify?
          </div>
        </div>
        <div id="video" className="overflow-clip rounded-xl">
          <YouTube
            ref={ytVideo}
            videoId="7ZKcLM7WpuE"
            className="aspect-video w-full"
          />
        </div>
      </div>

      {/* <div>
        <div className="flex flex-col lg:flex-row gap-x-4 relative text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-medium leading-[1em] max-w-full">
          <div className="main-spot main-spot2 rotate-180 top-[-80%] left-[-35%] scale-110">
            <img
              src="./assets/spot-03.png"
              className="img-spot-main"
              alt="Spot 02"
            />
          </div>
          <div className="flex-1 lg:h-64 md:flex-none lg:mt-[3em] z-2 relative">
            <span className="w-full whitespace-nowrap lg:text-right block">
              Your place for
            </span>
            <div className="w-full hidden lg:block absolute">
              <div className="flex-col space-y-4 mt-4 lg:pl-10 text-white/60 font-normal text-xl lg:text-2xl">
                <div className="">
                  Join quests from community-oriented brands and start exploring
                  today!
                </div>
                <ScrollIntoView
                  selector="#tournaments"
                  className="hover:text-white/80 cursor-pointer"
                  scrollOptions={{
                    block: 'center',
                  }}
                >
                  <Button className="block py-3">Explore tournaments</Button>
                </ScrollIntoView>
              </div>
            </div>
          </div>
          <div className="grow mt-[0em] md:mt-[-4dem] lg:mt-0 ">
            <span className="hidden lg:block">
              <DispersiveList />
            </span>
            <span className="block lg:hidden">
              <DispersiveList isTopAligh />
            </span>
          </div>
        </div>
        <div className="lg:hidden">
          <div className="mt-4 flex-col space-y-4 lg:pl-10 text-white/60 font-normal text-xl lg:text-2xl">
            <div className="">
              Join quests from community-oriented brands and start exploring
              today!
            </div>
            <Button className="block py-3">Explore tournaments</Button>
          </div>
        </div>
      </div> */}

      <div className="space-y-4 pt-12" id="templates">
        <div className="flex flex-col justify-center max-w-4xl">
          <div className="text-4xl md:text-5xl lg:text-7xl font-bold">
            Templates for absolutely any onboarding!
          </div>
          <div className="lg:pl-10 text-white/60 font-normal text-xl lg:text-2xl space-y-4 mt-6">
            <p>
              Ready-made game scenarios and designs. Benefit from 100+ world
              most engaging game mechanics and customize existing templates.
              Characters, leaderboards, loyalty points, collaborative tasks, and
              quality 3D effects to choose from.
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-4">
          <IntegrationSampleElement
            title="Turn Boring DEX onboarding into a tournament."
            subtitle="Character explains how to use platform and motivate users to trade more."
            image="/templatesPreviews/dexilon.jpg"
            video="/templatesPreviews/dexilon_small.mp4"
            fullVideoId="qWhOz3-M_gg"
          />
          <IntegrationSampleElement
            title="Animated 3D rewards"
            subtitle="3D effects create a more engaging and interactive experience for your customers, which can help to increase loyalty and retention."
            image="/templatesPreviews/present.jpg"
            video="/templatesPreviews/present_small.mp4"
            fullVideoId="4m28cVAT9sg"
          />
          <IntegrationSampleElement
            title="Attention Grabbers"
            subtitle="Attention-grabbing elements make your website more dynamic and engaging for your users."
            image="/templatesPreviews/effects.jpg"
            video="/templatesPreviews/effects_small.mp4"
            fullVideoId="kYaLnN2ThRQ"
          />
          <IntegrationSampleElement
            title="Turn an ecommerce website into a gaming quest"
            subtitle="Gamify the shopping experience by creating challenges and quests that customers can complete to earn rewards."
            image="/templatesPreviews/coffee.jpg"
            video="/templatesPreviews/coffee_small.mp4"
            fullVideoId="QqszFRofO4Q"
          />
          <IntegrationSampleElement
            title="Gaming Quiz about your product in the format of tournament"
            subtitle="Using a quiz about your product in the format of a gaming tournament is a great way to increase knowledge about your product and take the first steps towards learning about it in a fun way"
            image="/templatesPreviews/here.jpg"
            video="/templatesPreviews/here_small.mp4"
            fullVideoId="unPEnj4Utp4"
          />
        </div>
      </div>

      <div className="space-y-16 relative z-2 " id="pricing">
        <div className="main-spot main-spot3 z-0 top-0 left-1/3 rotate-90 scale-125">
          <img
            src="./assets/spot-03.png"
            className="img-spot-main"
            alt="Spot 01"
          />
        </div>
        <div className="flex flex-col justify-center z-2 relative" id="pricing">
          <div className="text-4xl md:text-5xl lg:text-7xl font-bold">
            Pricing
          </div>
          <div className="lg:pl-10 text-white/60 font-normal text-xl lg:text-2xl space-y-4 mt-6">
            <p>
              We provide companies with no-code templates to build engaging
              onboarding.
            </p>
          </div>
        </div>
        <Pricing />
      </div>

      <div className="space-y-4" id="tournaments">
        <div className="flex flex-col justify-center max-w-4xl">
          <div className="text-4xl md:text-5xl lg:text-7xl font-bold">
            Tournaments
          </div>
          <div className="lg:pl-10 text-white/60 font-normal text-xl lg:text-2xl space-y-4 mt-6">
            <p>
              Stemmy is a place where you Learn, Play and Explore. Join
              tournaments from our partners. Explore new companies, learn and
              collaborate via gaming experiences.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-4">
          <TournamentsCard
            link="https://512.coffee/"
            image="./tournaments/logo512-tm.jpg"
            header="Coffee kings"
            description="512 Coffe"
            date="Finished"
            prize="Coffee mix"
            currency=""
          />
          <TournamentsCard
            link="https://testnet.dexilon.io/swaps/eth_usdt"
            image="./tournaments/dexilon_v2.jpg"
            header="Dexilon Traders"
            description="Dexilon platform"
            date="Finished"
            prize="150"
            currency="$"
          />

          <TournamentsCard
            link="https://herewallet.app/stemmy/"
            image="./assets/TournamentCard01.png"
            header="NEAR Heroes"
            description="NEAR Mobile Wallet"
            date="Finished"
            prize="400"
            currency="Inr"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
