import PlansAndPricing from '../../components/simpleMainPage/plansAndPricing/PlansAndPricing';
import Templates from '../../components/simpleMainPage/templates/Templates';
import WellKnownCompanies from '../../components/simpleMainPage/wellKnownCompanies/WellKnownCompanies';
import WhatGamifyToday from '../../components/simpleMainPage/whatGamifyToday/WhatGamifyToday';
import WorkingProcess from '../../components/simpleMainPage/workingProcess/WorkingProcess';

const SimpleMainPage: React.FC = () => {
  return (
    <>
      <WhatGamifyToday />
      <WellKnownCompanies />
      <Templates />
      <PlansAndPricing />
      <WorkingProcess />
    </>
  );
};

export default SimpleMainPage;
