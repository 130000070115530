import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import ContactSalesPopupWrapper from '../contactSalesPopup/ContactSalesPopupWrapper';
import Button, {ButtonStyle} from '../form/button/Button';
import Toggle from '../form/switch/Toggle';
import PricingPopup from '../pricingPopup/PricingPopup';
import PricingCard, {CardStyle} from './PricingCard';

interface PricingDao {
  isAnnually: boolean;
}

export const prices = {
  basicMonthly: {
    price: 199, //2388
    paymentUrl: 'https://buy.stripe.com/9AQdUrbzI4Np7vybJ2',
  },
  basicAnnually: {
    price: 135, //1188
    paymentUrl: 'https://buy.stripe.com/14k3fNdHQbbN4jm00l',
    saving: 768,
  },
  proMonthly: {
    price: 299,
    paymentUrl: 'https://buy.stripe.com/5kA2bJ0V42Fh7vy14n',
  },
  proAnnually: {
    price: 225,
    paymentUrl: 'https://buy.stripe.com/fZe7w39rA3Jl7vyaEW',
    saving: 888,
  },
};

const Pricing: React.FC = (className) => {
  const [isAnnually, setIsAnnually] = useState(false);
  const [tariff, setTariff] = useState('basic');
  const {setValue, getValues, watch} = useForm<PricingDao>({
    defaultValues: {isAnnually: false},
  });

  useEffect(() => {
    const subscription = watch((data) => {
      if (data.isAnnually !== undefined) {
        setIsAnnually(data.isAnnually);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getLinkToPay = useCallback(() => {
    if (tariff === 'basic') {
      return isAnnually === true
        ? prices.basicAnnually.paymentUrl
        : prices.basicMonthly.paymentUrl;
    } else {
      return isAnnually === true
        ? prices.proAnnually.paymentUrl
        : prices.proMonthly.paymentUrl;
    }
  }, [isAnnually, tariff]);

  const onClickCard = useCallback(
    (data: {tariff?: string; annual?: boolean}) => {
      if (data.tariff) {
        setTariff(data.tariff);
      }
      if (data.annual === false || data.annual === true) {
        setIsAnnually(data.annual);
        setValue('isAnnually', data.annual);
      }
    },
    [setValue],
  );

  return (
    <>
      <div className="flex justify-end items-center gap-6 text-2xl font-bold text-white relative">
        Monthly
        <Toggle
          name="isAnnually"
          setValue={setValue}
          getValues={getValues}
          checked={isAnnually}
        />
        Annually
      </div>
      <div
        className={classNames(
          className,
          'flex-col lg:flex-row flex gap-8 justify-center relative',
        )}
      >
        <PricingCard
          cardStyle={CardStyle.Grey}
          className="flex-1"
          title="Stemmy Basic"
          price={
            isAnnually
              ? `$${prices.basicAnnually.price}`
              : `$${prices.basicMonthly.price}`
          }
          saving={isAnnually ? prices.basicAnnually.saving : undefined}
          interval="p/month"
          // interval={isAnnually ? 'p/year' : 'p/month'}
          assets={[
            'Up to 10 templates',
            'Analytics Dashboard',
            'Access to Influencers',
          ]}
          button="Buy"
        >
          <PricingPopup
            tariffNameChecked={tariff}
            isAnnually={isAnnually}
            onClickCard={onClickCard}
            linkToPay={getLinkToPay()}
            priceAnnualy={`$${prices.basicAnnually.price}`}
            priceMonthly={`$${prices.basicMonthly.price}`}
            savings="$768"
          >
            <Button
              className="w-full py-4 text-2xl"
              buttonStyle={ButtonStyle.Gray}
              onClick={() => {
                setTariff('basic');
              }}
            >
              Buy
            </Button>
          </PricingPopup>
        </PricingCard>
        <PricingCard
          className="flex-1"
          title="Stemmy Pro"
          price={
            isAnnually
              ? `$${prices.proAnnually.price}`
              : `$${prices.proMonthly.price}`
          }
          saving={isAnnually ? prices.proAnnually.saving : undefined}
          // interval={isAnnually ? 'p/year' : 'p/month'}
          interval="p/month"
          assets={[
            'Unlimited templates',
            'Analytics Dashboard',
            'UGC Mode',
            'Web3 Kit',
            'Access to Influencers',
          ]}
          button="Buy"
        >
          <PricingPopup
            tariffNameChecked={tariff}
            isAnnually={isAnnually}
            onClickCard={onClickCard}
            linkToPay={getLinkToPay()}
            priceAnnualy={`$${prices.proAnnually.price}`}
            priceMonthly={`$${prices.proMonthly.price}`}
            savings="$888"
          >
            <Button
              className="w-full py-4 text-2xl"
              buttonStyle={ButtonStyle.White}
              onClick={() => {
                setTariff('pro');
              }}
            >
              Buy
            </Button>
          </PricingPopup>
        </PricingCard>
        <PricingCard
          cardStyle={CardStyle.Grey}
          className="flex-1"
          title="Stemmy Enterprise"
          price="Contact us"
          assets={[
            'Unlimited templates',
            'Analytics Dashboard',
            'Gamification by request',
            'Metaverse Integration',
          ]}
          button="Contact Sales"
        >
          <ContactSalesPopupWrapper>
            <Button
              className="w-full py-4 text-2xl"
              buttonStyle={ButtonStyle.Gray}
            >
              Contact sales
            </Button>
          </ContactSalesPopupWrapper>
        </PricingCard>
      </div>
    </>
  );
};

export default Pricing;
