import classNames from 'classnames';
import {motion} from 'framer-motion';
import {ButtonStyle} from './Button';
import './Button.css';

interface ButtonStyleDef {
  common: string;
  disabled: string;
  normal: string;
}

interface ButtonAnimationsDef {
  animate: object;
  transition: object;
}

interface ButtonAnimationsObject {
  [name: string]: ButtonAnimationsDef;
}

export const ButtonAnimations: ButtonAnimationsObject = {
  Pulse: {
    animate: {
      scale: [1, 1.1, 1, 1.1, 1],
      backgroundColor: [
        'rgba(255, 255, 255, 0.1)',
        'rgba(255, 255, 255, 0.2)',
        'rgba(255, 255, 255, 0.1)',
        'rgba(255, 255, 255, 0.2)',
        'rgba(255, 255, 255, 0.1)',
      ],
    },
    transition: {repeat: Infinity, repeatDelay: 1, duration: 2},
  },
};

type MotionButtonProps = {
  buttonStyle?: ButtonStyleDef;
  animation?: ButtonAnimationsDef;
  animateDelay?: number;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const MotionButton: React.FC<MotionButtonProps> = ({
  className,
  type = 'button',
  children,
  disabled = false,
  buttonStyle = ButtonStyle.Round,
  animation,
  animateDelay,
  ...rest
}) => {
  return (
    <motion.button
      type={type}
      className={classNames(
        'flex justify-center items-center',
        buttonStyle.common,
        disabled ? buttonStyle.disabled : buttonStyle.normal,
        className,
      )}
      disabled={disabled}
      animate={animation ? animation.animate : false}
      transition={
        animation
          ? {...animation.transition, delay: animateDelay ? animateDelay : 0}
          : undefined
      }
      // {...rest}
    >
      {children}
    </motion.button>
  );
};

export default MotionButton;
