import React, {useState} from 'react';
import _uniqueId from 'lodash/uniqueId';
import './Input.scoped.css';
import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import classNames from 'classnames';

type InputParameters<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label?: string;
  name: TFieldName;
  type?: string;
  autoComplete?: boolean;

  options?: RegisterOptions<TFieldValues, TFieldName>;
  registration: {
    register: UseFormRegister<TFieldValues>;
    errors: DeepMap<DeepPartial<FieldValues>, FieldError>;
  };
} & React.HTMLAttributes<HTMLDivElement>;

const Input: <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  p: InputParameters<T, TName>,
) => React.ReactElement<InputParameters<T, TName>> = ({
  label,
  type,
  registration,
  name,
  options,
  autoComplete = false,
  ...rest
}) => {
  const [id] = useState(_uniqueId('input-'));

  const exactType = type || 'text';
  return (
    <div {...rest}>
      <div className="relative">
        <input
          id={id}
          placeholder={label}
          type={exactType}
          className={classNames(
            'h-full w-full p-4 font-semibold text-md transition-all rounded-lg text-white',
            registration.errors[name]
              ? 'error'
              : 'border-gray-300 focus:border-ffocusoutline',
          )}
          {...registration.register(name, options)}
          autoComplete={autoComplete ? undefined : 'off'}
        />
      </div>
    </div>
  );
};

export default Input;
