import {useCallback, useState} from 'react';
import SketchfabViewer from '../../components/sketchfabViewer/SketchfabViewer';
import {SketchfabCameraPosition} from '../../components/sketchfabViewer/SketchfabViewerEditorVersion';
import classNames from 'classnames';

type BookAnimationProps = React.HTMLProps<HTMLDivElement>;

// Idle - 2c91536832434e1e9ecc06f99c50eaac
// Open - 131e0e1e893244b799559c170b190390 (On hover)
// Animation - 3ad29542a3154937b3a16c7fa7642435 (On click)
// End - 5c314d0c4533411c8307e2f53d5e79a0 (end)

const BookAnimation: React.FC<BookAnimationProps> = ({className, ...rest}) => {
  const [currentAnimation, setCurrentAnimation] = useState(
    '2c91536832434e1e9ecc06f99c50eaac',
  );
  const [cycleMode, setCycleMode] = useState<
    'loopOne' | 'loopAll' | 'one' | 'all'
  >('loopOne');

  const [triggerOnEnded, setTriggerOnEnded] = useState(false);

  const [, setAnimationFired] = useState(false);

  const [customCamPos, setCustomCamPos] = useState<
    SketchfabCameraPosition | undefined
  >(
    // !isMobile
    //   ? {
    //       postion: [
    //         0.28208766695683507, -1.3118701159648218, 11.364982694456593,
    //       ],
    //       target: [
    //         0.3654593088095622, -0.21348560650474566, 0.43004868156983567,
    //       ],
    //       duration: 1,
    //     }
    //   :
    {
      postion: [-0.01563907089090389, -0.22218406258934015, 8.575505326938996],
      target: [-0.01219084763554338, -0.09441376684654217, 0.4391032919479219],
      duration: 1,
    },
  );

  const setFinAnimation = useCallback(() => {
    if (!triggerOnEnded) return;
    setCurrentAnimation('5c314d0c4533411c8307e2f53d5e79a0');
    setCycleMode('loopOne');
  }, [triggerOnEnded]);

  const setAnimation = useCallback(() => {
    setCurrentAnimation('3ad29542a3154937b3a16c7fa7642435');
    setCycleMode('loopAll');
    setCustomCamPos({
      postion: [-0.7198329474433696, -3.898795155063276, 13.322761948113763],
      target: [1.2395510597709893, -0.5935019709243056, 0.46556309678379987],
      duration: 1,
    });
    setTriggerOnEnded(true);
  }, []);

  const onModelClick = useCallback(() => {
    setAnimationFired((old) => {
      if (!old) {
        setAnimation();
      }

      return true;
    });
  }, [setAnimation, setAnimationFired]);

  return (
    <SketchfabViewer
      modelId="5b3e244aaa974225819fa772d2c75c02"
      className={classNames(className)}
      autostart
      currentAnimationUID={currentAnimation}
      cycleMode={cycleMode}
      onAnimationEnded={() => setFinAnimation()}
      currentCameraPosition={customCamPos}
      onElClick={onModelClick}
      {...rest}
    />
  );
};

export default BookAnimation;
