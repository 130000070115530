import YouTube from 'react-youtube';
import HeaderBlock from '../headerBlock/HeaderBlock';
import ButtonGray from '../buttonGray/ButtonGray';
import SmallHeaderBlock from '../smallHeaderBlock/SmallHeaderBlock';

export interface VideoBlockProps {
  id?: string;
}

const VideoBlock: React.FC<VideoBlockProps> = ({id}) => {
  return (
    <>
      <HeaderBlock text="Video" className="pb-10" />
      <YouTube
        videoId={id ? id : '7ZKcLM7WpuE'}
        className="aspect-video w-full"
      />
      <SmallHeaderBlock text="Animated 3D rewards" />
      <p className="text-base lg:text-lg leading-[normal] font-spaceGrotesk">
        During the initial consultation, we will discuss your business goals and
        objectives, target audience, and current marketing efforts. This will
        allow us to understand your needs and tailor our services to best fit
        your requirements.
      </p>
      <ButtonGray text="Gamify the website" className="mt-8" />
    </>
  );
};

export default VideoBlock;
