import {FaInstagram, FaTelegramPlane, FaTwitter} from 'react-icons/fa';
import ContactSalesPopupWrapper from '../contactSalesPopup/ContactSalesPopupWrapper';
import Button, {ButtonStyle} from '../form/button/Button';
import MotionButton, {ButtonAnimations} from '../form/button/MotionButton';
import Logo from '../logo/Logo';
import './Footer.css';

const Footer: React.FC = () => (
  <div className="footer text-white">
    <div className="upper w-full py-10 lg:py-20 flex flex-col gap-10">
      <div className="text-3xl  w-full text-center">
        Knowledge is your cheat code
      </div>
      <div className="grid lg:flex gap-2 items-center mx-auto block">
        <ContactSalesPopupWrapper>
          <Button buttonStyle={ButtonStyle.Lightgray}>Request a demo</Button>
        </ContactSalesPopupWrapper>
        <div className="flex gap-2 justify-center">
          {/* <a href="https://discord.com/invite/dm9jePYrqs">
            <MotionButton
              type="submit"
              buttonStyle={ButtonStyle.Round}
              animation={ButtonAnimations.Pulse}
            >
              <FaDiscord />
            </MotionButton>
          </a> */}
          <a href="https://t.me/stemmy_io">
            <MotionButton
              type="submit"
              buttonStyle={ButtonStyle.Round}
              animation={ButtonAnimations.Pulse}
              animateDelay={0.15}
            >
              <FaTelegramPlane />
            </MotionButton>
          </a>
          <a href="https://twitter.com/stemmy_io">
            <MotionButton
              type="submit"
              buttonStyle={ButtonStyle.Round}
              animation={ButtonAnimations.Pulse}
              animateDelay={0.3}
            >
              <FaTwitter />
            </MotionButton>
          </a>
          <a href="https://www.instagram.com/stemmy.io">
            <MotionButton
              type="submit"
              buttonStyle={ButtonStyle.Round}
              animation={ButtonAnimations.Pulse}
              animateDelay={0.45}
            >
              <FaInstagram />
            </MotionButton>
          </a>
        </div>
      </div>
    </div>
    <div className="lower w-full">
      <div className="flex-row space-y-4 text-center lg:text-start lg:flex max-w-7xl py-10 lg:py-20 px-10 mx-auto place-content-between">
        <div>
          <Logo showLogo />
        </div>
        <div>
          {/* <div className="font-bold text-xl">Subscribe to our blog</div>
          <a
            className="text-xl text-white/60 hover:text-white/80"
            href="http://blog.stemmy.io"
          >
            blog.stemmy.io
          </a> */}
        </div>
        <div className="mt-0">
          <div className="font-bold text-xl">Contact us</div>
          <a
            className="text-xl text-white/60 hover:text-white/80"
            href="mailto:content@stemmy.io"
          >
            content@stemmy.io
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
