import React from 'react';
import {Outlet} from 'react-router-dom';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';

const BasicLayout: React.FC = () => (
  <div className="flex flex-col justify-between bg-black max-w-full overflow-hidden">
    <Header />
    <div
      // className="w-full mt-24 pb-8 mb-auto max-w-screen-xl px-8 xl:px-0 mx-auto"
      className="lg:max-w-7xl block mx-auto mb-auto text-white px-4 md:px-24 lg:px-28 xl:px-32 max-w-full"
    >
      <Outlet />
    </div>
    <Footer />
  </div>
);

export default BasicLayout;
