import React from 'react';
import './TournamentsCard.scoped.css';

interface TournamentsCardProps {
  link: string;
  image: string;
  imageAlt?: string;
  header: string;
  description: string;
  date: string;
  prize: string;
  currency: string;
}

const TournamentsCard: React.FC<TournamentsCardProps> = ({
  link,
  image,
  imageAlt = 'TournamentsCard',
  header,
  description,
  date,
  prize,
  currency = '$',
}) => {
  return (
    <div className="bg-[rgba(255,_255,_255,_0.1)] rounded-[32px] flex flex-col flex-nowrap gap-0 h-min min-w-[200px] p-0 w-full relative max-w-[352px]">
      <div className="aspect-square flex flex-nowrap gap-[10px] overflow-hidden justify-center items-center cursor-pointer p-[14px] relative w-full">
        <a
          href={link}
          className="aspect-square rounded-[20px] block overflow-hidden relative no-underline will-change-transform"
        >
          <img
            src={image}
            alt={imageAlt}
            className="pointer-events-none select-none block w-full h-full object-cover object-center max-w-[330px]"
          />
        </a>
      </div>
      <div className="w-full relative flex flex-nowrap h-min gap-[2px] flex-col overflow-hidden p-[6px_16px_6px_20px]">
        <div className="flex flex-col transform-none shrink-0">
          <p className="text-lg font-semibold leading-[1.5em] text-[rgba(255,_255,_255,_0.7)] font-jost">
            {description}
          </p>
        </div>
        <div className="flex flex-col flex-nowrap gap-[10px] h-min overflow-hidden p-0 relative w-full">
          <h2 className="text-2xl font-semibold leading-[1.5em] text-white no-underline transform-none tracking-[-1px]">
            {header}
          </h2>
        </div>
      </div>
      <div className="flex flex-nowrap justify-center items-center gap-[10px] h-min overflow-hidden relative w-full p-[8px_14px_14px]">
        <div className="flex flex-row flex-[1_0_0px] justify-center items-center flex-nowrap gap-0 bg-[rgba(255,_255,_255,_0.1)] rounded-[20px] h-min p-0 relative will-change-transform overflow-hidden">
          <div className="flex flex-col flex-nowrap justify-center items-center self-stretch flex-[1_0_0px] gap-0 overflow-hidden relative p-[10px_0px_10px_20px]">
            <div className="flex flex-col shrink-0 transform-none opacity-70 relative whitespace-pre-wrap w-full break-words">
              <p className="text-xs leading-[1.5em] text-white font-jost">
                Date
              </p>
            </div>

            <div className="flex flex-col transform-none shrink-0 relative whitespace-pre-wrap w-full break-words">
              <p className="text-lg leading-6 text-white font-jost font-medium">
                {date}
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col flex-nowrap self-stretch flex-[1_1_0px] gap-0 relative p-[10px_0px_10px_16px] overflow-hidden">
            <div className="absolute inset-0">
              <div className="tournamentCardPrizeBgImage"></div>
            </div>
            <div className="flex flex-col shrink-0 transform-none opacity-90 w-full relative whitespace-pre-wrap break-words">
              <p className="text-xs italic leading-[1.5em] text-white font-jost">
                {' '}
                Prize
              </p>
            </div>
            <div className="flex items-center flex-[0_0_auto] flex-nowrap gap-[4px] h-min overflow-hidden p-0 relative w-full">
              <div className="flex flex-col shrink-0 transform-none relative whitespace-pre">
                <p className="text-xl italic font-black leading-6 text-white font-jost">
                  {prize}
                </p>
              </div>
              <div className="flex flex-col shrink-0 transform-none relative whitespace-pre break-words">
                <p className="text-xl italic font-black leading-6 text-white font-jost">
                  {currency}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentsCard;
