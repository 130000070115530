import classNames from 'classnames';

export interface ButtonGrayProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
}

const ButtonGray: React.FC<ButtonGrayProps> = ({text, className, ...rest}) => {
  return (
    <div
      className={classNames(
        'px-[35px] py-5 text-xl leading-7 rounded-[14px] border-[#191A23] border border-solid whitespace-nowrap cursor-pointer hover:bg-[#191A23] hover:text-white w-fit',
        className,
      )}
      {...rest}
    >
      {text}
    </div>
  );
};

export default ButtonGray;
