import ButtonBlack from '../buttonBlack/ButtonBlack';
import HeaderBlock from '../headerBlock/HeaderBlock';
import {dataPricing} from './PricingData';

const PlansAndPricing: React.FC = () => {
  return (
    <div className="pt-3.5">
      <HeaderBlock
        id="pricing"
        text="Plans and pricing"
        className="whitespace-nowrap"
      />
      <div className="flex gap-4 md:gap-10 py-8 flex-wrap md:flex-nowrap">
        {dataPricing.map((price, index) => {
          return (
            <div
              key={index}
              className="px-[35px] py-[40px] rounded-[45px] border border-solid border-[#191A23] main-simple-shadow flex flex-col gap-7 w-full"
            >
              <div className="flex flex-col gap-2">
                <p className="font-spaceGrotesk text-4xl leading-[normal] font-medium">
                  Stemmy Basic
                </p>
                <p className="font-spaceGrotesk">
                  <span className="text-2xl font-bold leading-[normal]">
                    {price.price ? '$' + price.price : 'Contact us'}
                  </span>
                  {price.price && (
                    <span className="pl-1.5 text-lg font-medium leading-[normal]">
                      p/month
                    </span>
                  )}
                </p>
              </div>
              <div className="h-[1px] bg-black" />
              <div className="flex flex-col gap-[15px] pl-[25px]">
                {price.advantages.map((adv) => {
                  return (
                    <li
                      key={adv}
                      className="text-xl leading-[92%] tracking-[-0.4px]"
                    >
                      {adv}
                    </li>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <ButtonBlack text="Compare Pricing" className="mx-auto" />
    </div>
  );
};

export default PlansAndPricing;
