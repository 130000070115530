import emoji1 from '../../../assets/simpleMainPage/templates/option-emoji-01.png';
import emoji2 from '../../../assets/simpleMainPage/templates/option-emoji-02.png';
import emoji3 from '../../../assets/simpleMainPage/templates/option-emoji-03.png';
import leaderboard1 from '../../../assets/simpleMainPage/templates/option-leaderboard-01.png';
import leaderboard2 from '../../../assets/simpleMainPage/templates/option-leaderboard-02.png';
import leaderboard3 from '../../../assets/simpleMainPage/templates/option-leaderboard-03.png';
import loyalty1 from '../../../assets/simpleMainPage/templates/option-loyalty-01.png';
import loyalty2 from '../../../assets/simpleMainPage/templates/option-loyalty-02.png';
import loyalty3 from '../../../assets/simpleMainPage/templates/option-loyalty-03.png';

export const dataTemplates = [
  {
    title: 'Emoji',
    url: '/',
    options: [
      {label: 'Animated 3D rewards', image: emoji1},
      {label: 'Animated 3D rewards', image: emoji2},
      {label: 'Animated 3D rewards', image: emoji3},
    ],
  },
  {
    title: 'Leaderboard',
    url: '/',
    options: [
      {label: 'Animated 3D rewards', image: leaderboard1},
      {label: 'Animated 3D rewards', image: leaderboard2},
      {label: 'Animated 3D rewards', image: leaderboard3},
    ],
  },
  {
    title: 'Loyalty Program',
    url: '/',
    options: [
      {label: 'Animated 3D rewards', image: loyalty1},
      {label: 'Animated 3D rewards', image: loyalty2},
      {label: 'Animated 3D rewards', image: loyalty3},
    ],
  },
];
