import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import {AiFillCheckCircle} from 'react-icons/ai';
import {BasicElement} from '../../helpers/BasicElement';
import './Pricing.css';

export enum CardStyle {
  'Grey',
  'Colored',
}

interface PricingCardProps
  extends React.HTMLProps<HTMLDivElement>,
    BasicElement {
  cardStyle?: CardStyle;

  title: string;
  price: string;
  interval?: string;
  assets: string[];
  button: string;
  saving?: number;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  interval,
  assets,
  button,
  saving,

  className,
  cardStyle = CardStyle.Colored,
  children,
  ...rest
}) => {
  return (
    <div className={className} {...rest}>
      <div
        className={classNames(
          cardStyle == CardStyle.Grey && 'price-gray-bg',
          cardStyle == CardStyle.Colored ? 'price-colored-bg' : '',
          'p-8',
        )}
      >
        <div className="text-2xl font-bold">{title}</div>
        <div className="relative">
          <AnimatePresence>
            {saving && (
              <motion.div
                key={title}
                animate={{opacity: [0, 0.8], x: ['30%', '0%']}}
                exit={{opacity: [1, 0], x: ['0%', '30%']}}
                transition={{duration: 0.2}}
                className="text-text-lg flex justify-end gap-1 my-2"
              >
                <span className="text-[#bd00ff] bg-white rounded-full px-2 shadow-md">
                  {saving}$
                </span>
                <span>savings/year</span>
              </motion.div>
            )}
          </AnimatePresence>
          <span className="text-5xl font-bold mr-1">{price}</span>
          {interval && (
            <span className="text-lg text-white/60">{interval}</span>
          )}
        </div>
        <div className="space-y-4 text-lg my-4">
          {assets.map((asset, index) => (
            <div className="flex space-x-2.5" key={index}>
              <AiFillCheckCircle className="w-6 h-6" />
              <span>{asset}</span>
            </div>
          ))}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default PricingCard;
