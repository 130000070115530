import {useEffect} from 'react';

interface RedirectProps {
  href: string;
}

const Redirect: React.FC<RedirectProps> = ({href}) => {
  useEffect(() => {
    window.location.href = href;
  }, [href]);
  return <></>;
};

export default Redirect;
