import classNames from 'classnames';
import {useState} from 'react';
import {isMobile} from 'react-device-detect';
import ScrollIntoView from 'react-scroll-into-view';
import BurgerMenu from '../burgerMenu/BurgerMenu';
import Button, {ButtonStyle} from '../form/button/Button';
import Logo from '../logo/Logo';
import './Header.css';

const Header: React.FC = () => {
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);

  return (
    <div className="w-full headerbg flex flex-wrap px-12 pt-4 pb-2 sticky top-0 z-50">
      <Logo className="grow" />
      <BurgerMenu
        className="lg:hidden"
        isOpen={isOpenBurgerMenu}
        onClick={() => {
          setIsOpenBurgerMenu(!isOpenBurgerMenu);
        }}
      />
      <div
        className={classNames(
          'mt-4 lg:mt-0 grid gap-4 lg:w-auto w-full lg:flex text-xl text-white lg:gap-4 lg:block',
          !isOpenBurgerMenu && 'hidden',
        )}
      >
        <ScrollIntoView
          selector="#pricing"
          className="block"
          onClick={() => setIsOpenBurgerMenu(false)}
          scrollOptions={{
            block: isMobile ? 'start' : 'center',
          }}
        >
          <Button
            buttonStyle={
              isMobile ? ButtonStyle.Lightgray : ButtonStyle.Transparent
            }
            className="block w-full text-xl"
          >
            Plans&nbsp;&&nbsp;Pricing
          </Button>
        </ScrollIntoView>
        <ScrollIntoView
          selector="#templates"
          className="block"
          onClick={() => setIsOpenBurgerMenu(false)}
          scrollOptions={{
            block: 'start',
          }}
        >
          <Button className="block py-3 w-full">Templates</Button>
        </ScrollIntoView>
      </div>
    </div>
  );
};

export default Header;
