import {useState} from 'react';
import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import ScrollIntoView from 'react-scroll-into-view';
import {motion} from 'framer-motion';
import classNames from 'classnames';
import logo from '../../../assets/simpleMainPage/Logo.png';
import stemmy from '../../../assets/simpleMainPage/Stemmy.png';
import ButtonGray from '../buttonGray/ButtonGray';
import BurgerMenu from '../../burgerMenu/BurgerMenu';

const Header: React.FC = () => {
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);

  return (
    <>
      <header className="pt-[50px] flex justify-between items-center">
        <Link to="/">
          <div className="flex py-2.5 gap-2.5 items-center">
            <img src={logo} alt="Logo" />
            <img src={stemmy} alt="Stemmy" />
          </div>
        </Link>
        <nav className="flex-col md:flex-row justify-center items-center gap-4 lg:gap-10 hidden md:flex">
          <span className="justify-center items-center gap-2 hidden lg:inline-flex">
            <span className="bg-[#D170E8] rounded-[25px] text-white font-poppins text-xs font-medium leading-[normal] text-center px-2.5 py-1">
              new
            </span>
            <span className="text-center text-xl leading-[normal] font-medium border border-solid border-black rounded-[25px] px-4 py-2 whitespace-nowrap">
              Becoming a business partner on online
            </span>
          </span>
          <ScrollIntoView
            className="cursor-pointer"
            selector="#templates"
            onClick={() => setIsOpenBurgerMenu(false)}
            scrollOptions={{
              block: 'center',
            }}
          >
            <span className="text-xl leading-7 cursor-pointer">Templates</span>
          </ScrollIntoView>
          <ScrollIntoView
            className="cursor-pointer"
            selector="#pricing"
            onClick={() => setIsOpenBurgerMenu(false)}
            scrollOptions={{
              block: 'center',
            }}
          >
            <span className="text-xl leading-7">Pricing</span>
          </ScrollIntoView>
          <ButtonGray text="Gamify the website" />
        </nav>

        <BurgerMenu
          className="md:hidden text-black"
          isOpen={isOpenBurgerMenu}
          onClick={() => {
            setIsOpenBurgerMenu(!isOpenBurgerMenu);
          }}
          classBar="bg-black"
        />
      </header>
      <motion.div
        className={classNames(
          'gap-4 w-full text-xl overflow-hidden !grid md:!hidden',
        )}
        animate={{
          height: isOpenBurgerMenu ? 'auto' : '0px',
        }}
        transition={{duration: 0.3, ease: 'easeOut'}}
      >
        <ScrollIntoView
          selector="#templates"
          className="block cursor-pointer text-center hover:bg-[rgba(0,0,0,0.1)] hover:font-medium"
          onClick={() => setIsOpenBurgerMenu(false)}
          scrollOptions={{
            block: 'start',
          }}
        >
          <span className="text-xl leading-7">Templates</span>
        </ScrollIntoView>
        <ScrollIntoView
          selector="#pricing"
          className="block cursor-pointer text-center hover:bg-[rgba(0,0,0,0.1)] hover:font-medium"
          onClick={() => setIsOpenBurgerMenu(false)}
          scrollOptions={{
            block: 'start',
          }}
        >
          <span className="text-xl leading-7">Pricing</span>
        </ScrollIntoView>
      </motion.div>
    </>
  );
};

export default Header;
