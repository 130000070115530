import React, {useEffect, useState} from 'react';
import _uniqueId from 'lodash/uniqueId';
import {Switch} from '@headlessui/react';
import classNames from 'classnames';

import './Toggle.scoped.css';
import {
  FieldPath,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

type ToggleOptions<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TFieldName;
  label?: string;
  setValue: UseFormSetValue<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  checked?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Toggle: <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  p: ToggleOptions<T, TName>,
) => React.ReactElement<ToggleOptions<T, TName>> = ({
  name,
  label,
  checked = false,
  setValue,
  getValues,
}) => {
  const [id] = useState(_uniqueId('toggle-'));
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    console.log('Fired');
    const value = getValues(name) as boolean;
    setEnabled(value);
  }, [getValues, name]);

  useEffect(() => {
    setEnabled(checked);
  }, [checked]);

  return (
    <div className="flex gap-2" onClick={() => setEnabled(!enabled)}>
      <Switch
        id={id}
        checked={enabled}
        onChange={(checked: boolean) => {
          setEnabled(checked);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setValue(name, checked as any);
        }}
        className={classNames(
          'toggle-container items-center',
          enabled ? 'bg-green-600' : 'bg-gray-300',
          'relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            'toggle',
            enabled ? 'translate-x-9' : 'translate-x-0',
            'pointer-events-none inline-block rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </Switch>
      {label && (
        <span
          className={classNames(
            'mt-1.5',
            enabled ? 'text-green-600' : 'text-gray-800',
          )}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default Toggle;
