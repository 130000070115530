import {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {BasicElement} from '../../helpers/BasicElement';
import Button, {ButtonStyle} from '../form/button/Button';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import PricingPopupCard from './PricingPopupCard';
import './PricingPopup.scoped.css';

interface PricingPopupProps extends BasicElement {
  onOpened?: () => void;
  onClosed?: () => void;
  tariffNameChecked: string;
  isAnnually: boolean;
  onClickCard: (data: {tariff?: string; annual?: boolean}) => void;
  linkToPay: string;
  priceAnnualy: string;
  priceMonthly: string;
  savings: string;
}

const PricingPopup: React.FC<PricingPopupProps> = ({
  onOpened,
  onClosed,
  tariffNameChecked,
  onClickCard,
  isAnnually,
  linkToPay,
  children,
  priceAnnualy,
  priceMonthly,
  savings,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (openPopup && onOpened) onOpened();
    if (!openPopup && onClosed) onClosed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup]);

  return (
    <>
      <div onClick={() => setOpenPopup(true)}>{children}</div>
      <AnimatePresence>
        {openPopup && (
          <>
            <div
              className="fixed bg-[rgba(0,_0,_0,_0.8)] w-screen h-screen top-0 left-0 z-[55]"
              onClick={() => setOpenPopup(false)}
            />
            <div className="place-content-center flex items-center flex-nowrap gap-[10px] h-full left-0 p-0 fixed top-0 w-full z-[55] pointer-events-none">
              <motion.div
                animate={{y: ['30%', '0%']}}
                exit={{y: ['0%', '30%']}}
                transition={{duration: 0.4}}
                className="container-content"
              >
                <div className="popup-contact-sales gap-2 md:gap-10">
                  <div className="flex justify-between flex-nowrap h-min w-full relative">
                    <div
                      className="absolute top-0 right-0 cursor-pointer"
                      onClick={() => setOpenPopup(false)}
                    >
                      <AiOutlineCloseCircle
                        size={32}
                        className="pointer-events-none"
                      />
                    </div>
                  </div>
                  <h2 className="text-lg md:text-5xl font-bold">
                    {/* Choose your plan */}
                    {tariffNameChecked === 'basic'
                      ? 'Stemmy Basic'
                      : 'Stemmy Pro'}
                  </h2>
                  {/* <div className="flex w-full gap-2 flex-col md:flex-row">
                    <PricingPopupCard
                      title="Basic"
                      price={['$199/monthly', '$1999/annually']}
                      checked={tariffNameChecked === 'basic'}
                      onClick={() => onClickCard({tariff: 'basic'})}
                    />

                    <PricingPopupCard
                      title="Pro"
                      price={['$399/monthly', '$3999/annually']}
                      checked={tariffNameChecked === 'pro'}
                      onClick={() => onClickCard({tariff: 'pro'})}
                    />
                  </div> */}
                  <h2 className="text-lg md:text-3xl font-bold">
                    Choose your subscription cycle
                  </h2>
                  <div className="flex w-full gap-2 flex-col md:flex-row">
                    <PricingPopupCard
                      title="Monthly"
                      price={[priceMonthly]}
                      checked={isAnnually === false}
                      onClick={() => onClickCard({annual: false})}
                    />

                    <PricingPopupCard
                      title="Annual"
                      price={[priceAnnualy]}
                      savings={savings}
                      checked={isAnnually === true}
                      onClick={() => onClickCard({annual: true})}
                    />
                  </div>
                  <div className="w-full flex justify-center">
                    <a href={linkToPay} target="_blank" className="w-1/2">
                      <Button
                        className="w-full py-4 text-2xl"
                        buttonStyle={ButtonStyle.White}
                      >
                        Buy
                      </Button>
                    </a>
                  </div>
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default PricingPopup;
