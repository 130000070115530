import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import Button from '../form/button/Button';
import Input from '../form/input/Input';
import './ContactSalesPopus.scoped.css';
import {useContactUs} from '../../api/signup/ContactUs';
import {SignUpDAO} from '../../api/signup/dao/SignUpDAO';
import {GlobalNotificationErrorHandler} from '../../services/globalNotification/errorHandler';
import {ContactSalesFormStates} from './ContactSalesPopupWrapper';

interface ContactSalesPopupContentsProps {
  state: ContactSalesFormStates;
  setFormState: (state: ContactSalesFormStates) => void;
  closeRequest: () => void;
}

const ContactSalesPopup: React.FC<ContactSalesPopupContentsProps> = ({
  state,
  setFormState,
  closeRequest,
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<SignUpDAO>();

  const contactUs = useContactUs(GlobalNotificationErrorHandler, {
    onSuccess: () => {
      reset();
      setFormState('done');
    },
    onError: (err) => {
      GlobalNotificationErrorHandler(err);
      setFormState('default');
    },
  });

  const onSend = useCallback(
    (data: SignUpDAO) => {
      setFormState('loading');
      contactUs.mutate({
        ...data,
        about: data.about || '',
      });
    },
    [contactUs, setFormState],
  );

  if (state === 'done')
    return (
      <div className="w-full flex flex-col gap-6">
        <span className="mx-auto">Success!</span>
        <div className="text-xl text-center">
          Our team will contact you shortly
        </div>
        <Button className="py-2" onClick={closeRequest}>
          Ok
        </Button>
      </div>
    );

  if (state === 'loading')
    return (
      <div className="flex-wrap-reverse flex gap-5 mx-auto">
        <svg
          className="mx-auto animate-spin h-12 w-12 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <span className="text-center">Sending your request</span>
      </div>
    );

  return (
    <>
      <h2 className="text-[28px] sm:text-[38px] leading-[1.2em] text-white">
        Grow your community by gaming experience
      </h2>

      <form
        onSubmit={handleSubmit(onSend)}
        className="h-full w-full grid gap-[15px] form-grid-contact-sales font-semibold"
      >
        <div className="grid w-full grid-flow-row sm:grid-flow-col gap-[15px]">
          <Input
            label="Your name"
            type="text"
            name="name"
            registration={{register, errors}}
            options={{required: 'Enter name'}}
          />
          <Input
            label="Email"
            type="email"
            name="email"
            registration={{register, errors}}
            options={{required: 'Enter email'}}
          />
        </div>

        <Input
          label="Website link"
          name="about"
          registration={{register, errors}}
          options={{required: 'Enter your website link'}}
        />

        <Button
          type="submit"
          className="appearance-none inline-block w-full leading-[1.4em] outline-none rounded-[8px] p-[15px] font-semibold text-lg bg-contact-sales-submit-button cursor-pointer text-white opacity-100 hover:opacity-80 hover:filter-none"
        >
          Request a demo
        </Button>
      </form>
    </>
  );
};

export default ContactSalesPopup;
