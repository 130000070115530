/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';

import Viewer, {SketchfabApi} from '@sketchfab/viewer-api';
import classNames from 'classnames';
import './SketchfabViewer.scoped.css';

export type Vector3 = [number, number, number];
export interface SketchfabCameraPosition {
  postion: Vector3;
  target: Vector3;
  duration: number;
}

export interface SketchfabViewerPorps
  extends React.HTMLAttributes<HTMLIFrameElement> {
  modelId: string;

  autostart?: boolean;
  hideAllAnnotations?: boolean;
  showOnlyAnnotations?: number[];

  selectedAnnotation?: number;
  gotoAnnotation?: number;
  onAnnotationClicked?: (index: number) => void;
  currentAnimationUID?: string;
  cycleMode?: 'loopOne' | 'loopAll' | 'one' | 'all';
  currentCameraPosition?: SketchfabCameraPosition;

  onAnimationEnded?: () => void;
  onElClick?: () => void;
}

const SketchfabViewer: React.FC<SketchfabViewerPorps> = ({
  modelId,
  onAnnotationClicked,
  currentAnimationUID,
  cycleMode = 'loopOne',
  className,
  hideAllAnnotations = false,
  showOnlyAnnotations = [],
  autostart = false,
  selectedAnnotation = 0,
  currentCameraPosition,

  gotoAnnotation,
  onAnimationEnded,
  onElClick,

  ...others
}) => {
  const [api, setApi] = useState<SketchfabApi>();
  const [isViewerReady, setIsViewerReady] = useState(false);
  const [annotationsCount, setAnnotationsCount] = useState(-1);
  const viewerIframeRef = useRef(null);

  // const [isViewVisible, setIsViewVisible] = useState(false);

  // useEffect(() => {
  //   if (!isViewVisible) {
  //     const timeout = setTimeout(() => setIsViewVisible(true), 2000);
  //     return () => {
  //       clearTimeout(timeout);
  //     };
  //   }
  // }, [isViewVisible]);

  useEffect(() => {
    if (!gotoAnnotation || !isViewerReady || !api) return;

    api.gotoAnnotation(gotoAnnotation, {
      preventCameraAnimation: false,
      preventCameraMove: false,
    });
  }, [gotoAnnotation, isViewerReady]);

  //   useEffect(() => {
  //     if (!isViewerReady || !api) return;

  //     customAnnotations.forEach((element) => {
  //       api.updateAnnotation(element.index, {
  //         title: element.title,
  //         content: element.content,
  //       });
  //     });
  //   }, [customAnnotations, isViewerReady]);

  useEffect(() => {
    if (!isViewerReady || !api || annotationsCount === -1) return;

    if (hideAllAnnotations || showOnlyAnnotations.length > 0) {
      for (let i = 0; i < annotationsCount; i += 1) {
        if (!showOnlyAnnotations.includes(i)) {
          api.hideAnnotation(i);
          api.hideAnnotationTooltip(i);
        }
      }
    } else {
      for (let i = 0; i < annotationsCount; i += 1) {
        api.showAnnotation(i);
        api.showAnnotationTooltip(i);
      }
    }
  }, [
    hideAllAnnotations,
    annotationsCount,
    isViewerReady,
    showOnlyAnnotations,
  ]);

  useEffect(() => {
    if (!onAnnotationClicked || !isViewerReady || !api) return;

    api.addEventListener('annotationSelect', onAnnotationClicked);
  }, [isViewerReady]);

  useEffect(() => {
    if (!currentAnimationUID || !isViewerReady || !api) return;

    api.setCurrentAnimationByUID(currentAnimationUID);
  }, [isViewerReady, currentAnimationUID]);

  useEffect(() => {
    if (!currentCameraPosition || !isViewerReady || !api) return;

    api.setCameraLookAt(
      currentCameraPosition.postion,
      currentCameraPosition.target,
      currentCameraPosition.duration,
    );
  }, [isViewerReady, currentCameraPosition]);

  useEffect(() => {
    if (!onElClick || !isViewerReady || !api) return;

    api.addEventListener('click', (e) => {
      console.log(e.instanceID);
      onElClick();
    });
  }, [isViewerReady, onElClick]);

  useEffect(() => {
    if (!isViewerReady || !api) return;

    api.addEventListener('annotationSelect', (index: number) => {
      api.removeAnnotation(index);
    });
  }, [isViewerReady]);

  useEffect(() => {
    if (!isViewerReady || !api) return;

    api.setCycleMode(cycleMode);
  }, [isViewerReady, cycleMode]);

  useEffect(() => {
    if (!onAnimationEnded || !isViewerReady || !api) return;

    api.addEventListener('animationEnded', () => {
      onAnimationEnded();
    });
  }, [isViewerReady, onAnimationEnded]);

  useEffect(() => {
    const client = new Viewer(viewerIframeRef.current);
    setIsViewerReady(false);

    client.init(modelId, {
      ui_infos: 0,
      ui_controls: 0,
      graph_optimizer: 0,
      ui_watermark: 0,
      ui_hint: 0,
      transparent: 1,
      preload: 1,
      scrollwheel: 0,
      ui_stop: 0,
      double_click: 0,
      ui_loading: 0,
      ui_start: 0,
      autostart: autostart ? 1 : 0,
      annotation: selectedAnnotation,
      success: (data) => {
        // if (autostart) {
        //   data.start();
        // }

        data.addEventListener('viewerready', () => {
          data.setCycleMode(cycleMode);
          data.getAnnotationList((err, annotations) => {
            if (!err) setAnnotationsCount(annotations.length);
          });

          setApi(data);
          setIsViewerReady(true);
        });
      },
    });
  }, []);

  //   const getLookAt = useCallback(() => {
  //     api.getCameraLookAt((err: any, camera: any) => {
  //       window.console.log(camera.position); // [x, y, z]
  //       window.console.log(camera.target); // [x, y, z]
  //     });
  //   }, [api]);
  return (
    <div
      className={classNames('viewer-element relative', className)}
      onMouseOver={() => onElClick && onElClick()}
      aria-hidden="true"
    >
      <iframe
        ref={viewerIframeRef}
        title="sketchfab-viewer"
        className="w-full h-full"
        {...others}
      />
      <div
        className="lg:hidden absolute w-full h-full top-0 left-0"
        onClick={() => onElClick && onElClick()}
        onTouchMove={() => onElClick && onElClick()}
        aria-hidden="true"
      />
    </div>
  );
};

export default SketchfabViewer;
