import HeaderBlock from '../headerBlock/HeaderBlock';
import {dataTemplates} from './TemplatesData';
import CardTemplate from './CardTemplate';
import ButtonBlack from '../buttonBlack/ButtonBlack';

const Templates: React.FC = () => {
  return (
    <div className="pt-3.5">
      <HeaderBlock
        id="templates"
        text="Templates for absolutely any onboarding!"
        className="lg:whitespace-nowrap"
      />
      <div className="grid md:grid-cols-2 gap-4 lg:gap-10 pt-5 lg:pt-[68px]">
        {dataTemplates.slice(0, 4).map((template, index) => {
          return (
            <CardTemplate
              key={index}
              header={template.header}
              description={template.description}
              image={template.image}
              video={template.video}
              fullVideoId={template.fullVideoId}
            />
          );
        })}
      </div>
      {dataTemplates.length >= 4 && (
        <div className="pt-4 lg:pt-10">
          <CardTemplate
            header={dataTemplates[4].header}
            description={dataTemplates[4].description}
            image={dataTemplates[4].image}
            video={dataTemplates[4].video}
            fullVideoId={dataTemplates[4].fullVideoId}
            isBigCard
          />
        </div>
      )}
      <ButtonBlack text="Browse all templates" className="mt-10 mx-auto" />
    </div>
  );
};

export default Templates;
