import {useState} from 'react';
import HoverVideoPlayer from '../hoverVideoPlayer/HoverVideoPlayer';
import VideoPopup from '../hoverVideoPlayer/VideoPopup';

interface IntegrationSampleElementProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;

  video: string;
  fullVideoId: string;
  image: string;
}

const IntegrationSampleElement: React.FC<IntegrationSampleElementProps> = ({
  title,
  subtitle,
  image,
  video,
  fullVideoId,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  return (
    <div
      className="bg-[rgba(255,_255,_255,_0.1)] rounded-[32px] p-4 cursor-pointer"
      onClick={() => setIsPopupOpened(true)}
    >
      <div>
        <HoverVideoPlayer image={image} video={video} />
      </div>
      <div className="mt-3 font-jost px-2 mt-4">
        <p className="text-2xl font-semibold text-white">{title}</p>
        <p className="text-lg font-semibold text-[rgba(255,_255,_255,_0.7)]">
          {subtitle}
        </p>
      </div>

      <VideoPopup
        youtubeId={fullVideoId}
        isOpen={isPopupOpened}
        onClosed={() => setIsPopupOpened(false)}
      />
    </div>
  );
};

export default IntegrationSampleElement;
