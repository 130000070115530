import ScrollContainer from 'react-indiana-drag-scroll';
import HeaderBlock from '../headerBlock/HeaderBlock';
import {dataTemplates} from './TemplatesData';
import {Link} from 'react-router-dom';

export interface TemplatesBlockProps {}

const TemplatesBlock: React.FC<TemplatesBlockProps> = () => {
  return (
    <>
      <HeaderBlock text="Templates" className="pb-10" />
      <div className="flex flex-col gap-[33px]">
        {dataTemplates.map((template, index) => {
          return (
            <div key={index} className="flex flex-col gap-10">
              <div className="flex justify-between items-center">
                <h2 className="text-2xl md:text-3xl lg:text-5xl leading-[92%] font-bold">
                  {template.title}
                </h2>
                <Link
                  to={template.url}
                  className="text-[#CF7BFD] md:text-xl lg:text-2xl leading-[92%]"
                >
                  See all
                </Link>
              </div>
              <div className="">
                <ScrollContainer
                  className="scroll-container cursor-pointer flex gap-10 px-2 md:px-8 lg:px-[50px] scroll-smooth transition duration-100"
                  vertical={false}
                >
                  {template.options.map((t, i) => {
                    return (
                      <div
                        className="flex flex-col gap-6 min-w-[200px] md:min-w-[300px] lg:min-w-[530px]"
                        key={i}
                      >
                        <img src={t.image} alt={t.label} />
                        <h4 className="text-lg md:text-2xl lg:text-[30px] font-medium leading-[normal] px-[7px]">
                          {t.label}
                        </h4>
                      </div>
                    );
                  })}
                </ScrollContainer>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TemplatesBlock;
