export const dataTemplates = [
  {
    header: 'Turn Boring DEX onboarding into a tournament.',
    description:
      'Character explains how to use platform and motivate users to trade more.',
    image: '/templatesPreviews/dexilon.jpg',
    video: '/templatesPreviews/dexilon_small.mp4',
    fullVideoId: 'qWhOz3-M_gg',
  },
  {
    header: 'Animated 3D rewards',
    description:
      '3D effects create a more engaging and interactive experience for your customers, which can help to increase loyalty and retention.',
    image: '/templatesPreviews/present.jpg',
    video: '/templatesPreviews/present_small.mp4',
    fullVideoId: '4m28cVAT9sg',
  },
  {
    header: 'Attention Grabbers',
    description:
      'Attention-grabbing elements make your website more dynamic and engaging for your users.',
    image: '/templatesPreviews/effects.jpg',
    video: '/templatesPreviews/effects_small.mp4',
    fullVideoId: 'kYaLnN2ThRQ',
  },
  {
    header: 'Turn an ecommerce website into a gaming quest',
    description:
      'Gamify the shopping experience by creating challenges and quests that customers can complete to earn rewards.',
    image: '/templatesPreviews/coffee.jpg',
    video: '/templatesPreviews/coffee_small.mp4',
    fullVideoId: 'QqszFRofO4Q',
  },
  {
    header: 'Gaming Quiz about your product in the format of tournament',
    description:
      'Using a quiz about your product in the format of a gaming tournament is a great way to increase knowledge about your product and take the first steps towards learning about it in a fun way',
    image: '/templatesPreviews/here.jpg',
    video: '/templatesPreviews/here_small.mp4',
    fullVideoId: 'unPEnj4Utp4',
  },
];
