export const dataPricing = [
  {
    header: 'Stemmy Basic',
    price: 199,
    advantages: [
      'Basic integration for onboarding request',
      'Analytics Dashboard',
      '10 Basic Gamify Effects',
    ],
  },
  {
    header: 'Stemmy Pro',
    price: 299,
    advantages: [
      'Unlimited number of onboarding scenarios',
      'Analytics Dashboard',
      'unlimited number of effects',
    ],
  },
  {
    header: 'Stemmy Enterprise',
    advantages: [
      'Unlimited templates',
      'Analytics Dashboard',
      'Gamification by request',
      'Metaverse Integration',
    ],
  },
];
