import classNames from 'classnames';

export interface ButtonBlackProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
}

const ButtonBlack: React.FC<ButtonBlackProps> = ({
  text,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'py-5 px-[35px] rounded-[14px] bg-[#191A23] text-white text-center text-xl whitespace-nowrap cursor-pointer w-fit hover:bg-[#5F5BF0]',
        className,
      )}
      {...rest}
    >
      {text}
    </div>
  );
};

export default ButtonBlack;
