import classNames from 'classnames';
import './Logo.css';

interface LogoProps extends React.HTMLProps<HTMLDivElement> {
  showLogo?: boolean;
}

const Logo: React.FC<LogoProps> = ({className, showLogo = false, ...rest}) => {
  return (
    <div
      className={classNames(className, 'logo text-white font-semibold')}
      {...rest}
    >
      <a href="/" className="flex items-center">
        {showLogo && <img src="./logo/logo.svg" className="w-10 mr-4" />}
        <img src="./logo/stemmy.svg" className="h-6 mt-1" />
      </a>
    </div>
  );
};

export default Logo;
