import React from 'react';
import classNames from 'classnames';

interface PricingPopupCardProps {
  title?: string;
  subTitle?: string;
  desciption?: string;
  price?: string[];
  checked?: boolean;
  savings?: string;
  onClick?: () => void;
}

const PricingPopupCard: React.FC<PricingPopupCardProps> = ({
  title,
  subTitle,
  desciption,
  price,
  savings,
  checked = false,
  onClick = () => {},
}) => {
  return (
    <div
      className={classNames(
        'p-4 px-6 rounded-2xl flex flex-col w-full md:w-1/2 gap-2 cursor-pointer price-colored-bg hover:opacity-80 border-solid border-4',
        checked ? 'border-solid border-blue-200 ' : 'border-transparent',
      )}
      onClick={onClick}
    >
      {savings && <div className="text-lg font-bold">{savings} savings</div>}
      <div className="flex flex-col align-bottom mt-auto">
        <div className="flex gap-2 justify-between items-center">
          {title && (
            <div className="text-2xl font-bold text-center w-full">{title}</div>
          )}
          {subTitle && <div>{subTitle}</div>}
        </div>
        {desciption && <div className="text-sm">{desciption}</div>}
        {price && (
          <div className="justify-between text-center w-full text-lg font-semibold">
            {price?.map((p, index) => {
              if (index > 0) {
                return (
                  <React.Fragment key={index}>
                    | <span>{p}</span>
                  </React.Fragment>
                );
              }
              return <span key={index}>{p}</span>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingPopupCard;
