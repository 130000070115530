import {useState} from 'react';
import learnMore from '../../../assets/simpleMainPage/learnMore.png';
import HoverVideoPlayer from '../../hoverVideoPlayer/HoverVideoPlayer';
import VideoPopup from '../../hoverVideoPlayer/VideoPopup';
import classNames from 'classnames';

export interface CardTemplateProps extends React.HTMLProps<HTMLDivElement> {
  header: string;
  description: string;
  image: string;
  video: string;
  fullVideoId: string;
  isBigCard?: boolean;
}

const CardTemplate: React.FC<CardTemplateProps> = ({
  header,
  description,
  image,
  video,
  fullVideoId,
  isBigCard = false,
  ...rest
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  return (
    <div
      className="rounded-[45px] border border-solid border-[#191A23] bg-[#F3F3F3] main-simple-shadow pt-[20px] lg:pt-[50px] pr-[15px] lg:pr-[35.5px] pb-[20px] lg:pb-[30px] pl-[20px] lg:pl-[50.5px] flex flex-col justify-between"
      {...rest}
    >
      <h3 className="text-xl lg:text-3xl leading-[normal] font-medium">
        {header}
      </h3>
      <div className="flex flex-col-reverse lg:flex-row lg:justify-between h-full gap-[15px]">
        <div
          className={classNames(
            'w-full flex flex-col',
            isBigCard
              ? 'justify-around lg:w-[60%]'
              : 'justify-between lg:w-[35%]',
          )}
        >
          <p className="text-base lg:text-lg leading-[normal] py-4 font-spaceGrotesk">
            {description}
          </p>
          <p className="inline-flex items-center gap-[15px] cursor-pointer hover:font-semibold hover:underline">
            <img src={learnMore} alt="Learn more" />
            <span className="text-xl font-spaceGrotesk">Learn more</span>
          </p>
        </div>
        <div
          className={classNames(
            'w-full flex items-end cursor-pointer',
            isBigCard ? 'lg:w-[40%] lg:pl-20 lg:pt-8 lg:pb-4' : 'lg:w-[65%]',
          )}
          onClick={() => setIsPopupOpened(true)}
        >
          <HoverVideoPlayer image={image} video={video} rounded="small" />
        </div>
      </div>
      <VideoPopup
        youtubeId={fullVideoId}
        isOpen={isPopupOpened}
        onClosed={() => setIsPopupOpened(false)}
      />
    </div>
  );
};

export default CardTemplate;
