import ButtonGray from '../buttonGray/ButtonGray';
import what_will_you_gamify_today from '../../../assets/simpleMainPage/what_will_you_gamify_today.png';
import HeaderBlock from '../headerBlock/HeaderBlock';

const WhatGamifyToday: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      <HeaderBlock text="What will you gamify today?" />
      <h3 className="leading-[normal] text-lg md:text-2xl lg:text-[32px] text-[#B1B1B1] pt-3 pb-9 text-center">
        We provide companies with no-code templates to build engaging
        onboarding.
      </h3>
      <ButtonGray text="Request a demo" />
      <div className="relative aspect-[124/70] w-full pointer-events-none">
        <img
          src={what_will_you_gamify_today}
          alt="Safari Lightpic for gaming block"
          className="absolute top-[-13%]"
        />
      </div>
    </div>
  );
};

export default WhatGamifyToday;
