import {useCallback, useState} from 'react';
import {AiFillTwitterCircle, AiOutlineTwitter} from 'react-icons/ai';
import {BiLogoFacebook, BiLogoLinkedin} from 'react-icons/bi';
import {BsFacebook, BsTwitter} from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa';
import {IoLogoFacebook} from 'react-icons/io';
import {Link} from 'react-router-dom';

const Footer: React.FC = () => {
  const [email, setEmail] = useState('');

  const onSubscribe = useCallback(() => {
    if (!email.trim()) {
      return;
    }
    console.log('email sub:', email);
    setEmail('');
  }, [email]);

  return (
    <div className="pt-7 text-white">
      <div className="pt-5 md:pt-[55px] px-6 md:px-[60px] pb-5 md:pb-[50px] flex flex-col gap-4 md:gap-[50px] rounded-[45px_45px_0px_0px] bg-[#F3F3F3] text-[#292A32] border border-solid">
        <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-[155px]">
          <div className="flex gap-4 lg:gap-[40px] text-lg underline leading-[normal] flex-wrap">
            <Link to="/about">About us</Link>
            <Link to="/services">Services</Link>
            <Link to="/usecase">Use Cases</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/blog">Blog</Link>
          </div>

          <div className="flex gap-[20px]">
            <a href="#" target="_blank">
              <BiLogoLinkedin
                size={30}
                className="bg-black text-white rounded-full p-1"
              />
            </a>
            <a href="#" target="_blank">
              <BsFacebook size={30} className="bg-white rounded-full" />
            </a>
            <a href="https://twitter.com/stemmy_io" target="_blank">
              <AiOutlineTwitter
                size={30}
                className="bg-black text-white rounded-full p-1"
              />
              {/* <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="15" r="15" fill="white" />
                <path
                  d="M21.9961 11.4915C22.6854 10.9745 23.3058 10.3541 23.7884 9.63028C23.168 9.90602 22.4442 10.1128 21.7204 10.1818C22.4786 9.73369 23.0301 9.04435 23.3058 8.18267C22.6165 8.59628 21.8238 8.90648 21.031 9.07881C20.3417 8.35501 19.4111 7.94141 18.3771 7.94141C16.378 7.94141 14.758 9.56135 14.758 11.5604C14.758 11.8362 14.7925 12.1119 14.8614 12.3876C11.8628 12.2153 9.1744 10.7677 7.38212 8.59628C7.07192 9.11328 6.89959 9.73369 6.89959 10.423C6.89959 11.6638 7.51999 12.7668 8.51953 13.4216C7.93359 13.3872 7.34766 13.2493 6.86512 12.9736V13.008C6.86512 14.7659 8.10593 16.2135 9.76034 16.5581C9.48461 16.6271 9.13994 16.696 8.82973 16.696C8.58847 16.696 8.38166 16.6615 8.1404 16.6271C8.58847 18.0747 9.93267 19.1087 11.5182 19.1432C10.2773 20.1082 8.72633 20.6942 7.03745 20.6942C6.72725 20.6942 6.45152 20.6597 6.17578 20.6252C7.76126 21.6592 9.65694 22.2452 11.725 22.2452C18.3771 22.2452 21.9961 16.7649 21.9961 11.974C21.9961 11.8017 21.9961 11.6638 21.9961 11.4915Z"
                  fill="black"
                />
              </svg> */}
            </a>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between gap-4 lg:gap-[154px] w-full">
          <div className="flex flex-col gap-[27px]">
            <h2 className="rounded-[7px] bg-[#CF7BFD] px-[7px] text-xl font-medium leading-[normal] text-black w-fit">
              Contact us:
            </h2>
            <div className="flex flex-col gap-[20px] text-lg leading-[normal] font-normal">
              <a href="mailto:info@positivus.com">Email: info@positivus.com</a>
              <a href="tel:+5555678901">Phone: 555-567-8901</a>
              <div>
                Address: 1234 Main St <br />
                Moonstone City, Stardust State 12345
              </div>
            </div>
          </div>

          <form
            className="flex flex-col md:flex-row justify-between gap-[20px] px-4 lg:px-[40px] py-4 lg:py-[58px] rounded-[14px] bg-[#F3F3F3] w-full lg:w-[60%] h-fit border border-solid"
            onSubmit={(e) => {
              e.preventDefault();
              onSubscribe();
            }}
          >
            <div className="rounded-[14px] border border-solid border-black px-4 lg:px-[35px] py-2 lg:py-[22px] w-full flex items-center">
              <input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-transparent placeholder:text-[#292A32] placeholder:text-lg placeholder:leading-[normal]"
              />
            </div>
            <button
              type="submit"
              className="bg-[#CF7BFD] rounded-[14px] px-[35px] py-[20px] text-xl text-black w-full whitespace-nowrap cursor-pointer"
            >
              Subscribe to news
            </button>
          </form>
        </div>

        <hr className="h-[1px] bg-[#292A32]" />

        <div className="flex flex-col md:flex-row gap-4 md:gap-[40px] text-lg">
          <span>© 2023 Positivus. All Rights Reserved.</span>
          <Link to="/privacy" className="underline">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
