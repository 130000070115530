import React from 'react';
import {Outlet} from 'react-router-dom';
import Footer from '../../../components/simpleMainPage/footer/Footer';
import Header from '../../../components/simpleMainPage/header/Header';

const SimpleLayout: React.FC = () => (
  <div className="flex flex-col justify-between max-w-full overflow-hidden font-spaceGrotesk">
    <div className="max-w-[1440px] text-black px-2 md:px-10 lg:px-[100px] w-full mx-auto">
      <Header />
      <Outlet />
      <Footer />
    </div>
  </div>
);

export default SimpleLayout;
