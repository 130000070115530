import classNames from 'classnames';
import {useState} from 'react';
import {BsPlayFill} from 'react-icons/bs';

interface HoverVideoPlayerProps {
  image: string;
  video: string;
  rounded?: 'small' | 'big';
  style?: 'light' | 'dark';
}

const HoverVideoPlayer: React.FC<HoverVideoPlayerProps> = ({
  image,
  video,
  rounded = 'big',
  style = 'light',
}) => {
  const [isHover, setOnHover] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div
        className={classNames(
          style === 'light' && 'bg-gray-300/[.80]',
          'absolute p-4 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20',
        )}
      >
        <BsPlayFill size={30} />
      </div>
      <div className="pointer-events-none">
        <img
          src={image}
          className={classNames(
            'z-10',
            rounded === 'big' && 'rounded-[32px]',
            rounded === 'small' && 'rounded-[12px]',
          )}
        />
        {isHover && (
          <video
            src={video}
            autoPlay
            muted
            className="rounded-[32px] absolute top-0 left-0 w-full h-full z-30"
          />
        )}
      </div>
    </div>
  );
};

export default HoverVideoPlayer;
